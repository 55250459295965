import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

type Props = {
    children: React.ReactNode;
    passHref?: boolean;
    skipLocaleHandling?: boolean;
    locale?: string;
    href?: string;
    style?: React.CSSProperties;
};

const Link: FC<Props> = (props) => {
    const { children, passHref, skipLocaleHandling, locale, href, style, ...rest } = props;
    let shouldSkipLocaleHandling = skipLocaleHandling;
    const router = useRouter();
    let currentLocale = locale || (router.query.locale as string) || '';

    if (currentLocale.includes('undefined')) {
        currentLocale = currentLocale.replace('undefined', '');
    }

    let currentHref = href || '/' || router.asPath;

    if (currentHref === 'undefined') {
        currentHref = '/';
    }

    if (currentHref.indexOf('http') === 0) {
        shouldSkipLocaleHandling = true;
    }

    if (currentLocale && !shouldSkipLocaleHandling) {
        currentHref = currentHref
            ? `/${currentLocale}${href}`
            : router.pathname.replace('[locale]', currentLocale);
    }

    return (
        <>
            <NextLink legacyBehavior href={currentHref} passHref={passHref} style={style}>
                <a {...rest}>{children}</a>
            </NextLink>
        </>
    );
};

export default Link;
