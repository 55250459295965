import { Box } from '@chakra-ui/react';
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { NextPage } from 'next';
import Link from 'src/components/common/link/Link';

const ErrorPage: NextPage = () => {
    return (
        <>
            <Box left="50%" position="fixed" top="50%" transform="translate(-50%, -50%)">
                <Link passHref href="/home">
                    <FontAwesomeIcon icon={faRotateRight} size="3x" />
                </Link>
            </Box>
        </>
    );
};

export default ErrorPage;
